import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="About" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />
      <article className="post-content page-template  no-image">
        <div className="post-content-body">
          <h2>Gone, but never forgotten.</h2>
          <p>
            This site is to showcase the life of Eugene so future generations
            will know who he was, where they are from and know who they are.
          </p>
        </div>

        <figure className="kg-card kg-image-card">
          <Img fluid={data.pop.childImageSharp.fluid} className="kg-image" />
          <Img fluid={data.pop2.childImageSharp.fluid} className="kg-image" />
          <Img fluid={data.pop3.childImageSharp.fluid} className="kg-image" />
          <figcaption>Eugene D'Cruz</figcaption>
        </figure>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    pop: file(relativePath: { eq: "Poppee.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pop2: file(relativePath: { eq: "pop_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pop3: file(relativePath: { eq: "pop_3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
